import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { currency, storeApiUrl, useApiRequest } from '../store/Common'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { BlackColor, ColoredColor, WhiteColor } from '../components/pos/color';
import { useCommonUI } from '../context/UI';
import { toast } from 'react-toastify';

function OrderDetails() {

    const { orderId } = useParams();
    const {fetchData} = useApiRequest();
    const {openDialog} = useCommonUI();
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderItems, setOrderItems] = useState([]);
    const [bags, setBags] = useState([]);
    const [tagData, setTagData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [unpaidSubs, setUnpaidSubs] = useState(0);

    const getData = useCallback(async(oid) =>{
        setIsLoading(true);
        const resp = await fetchData('order/details/'+oid);
        const resp1 = await fetchData('order/items/'+oid);
        if(resp && resp1){
            let tagTypeData = {};
            for(const itype of resp.data.itemTypes){
                let tagReady = true;
                let serviceId = itype.service.id;
                for(const oItem of resp1.data){
                    if(oItem.service === serviceId && (oItem.status === "Picked Up" || oItem.status === "Picked Up at Store" || oItem.status === "Received at Store")){
                        tagReady = false;
                    }
                }
                tagTypeData[serviceId] = tagReady;
            }
            setTagData(tagTypeData);
            setOrderDetails(resp.data);
            setOrderItems(resp1.data);
            setBags(resp.bags);
            setUnpaidSubs(resp.unpaidSubs);
        }
        setIsLoading(false);
    },[fetchData])

    useEffect(()=>{
        getData(orderId);
    },[getData, orderId]);

    const cancelOrder = () =>{
        if(isCancelling){
            return;
        }
        openDialog("CANCEL ORDER?","Amount will be refunded automatically if paid online.<br>This action cannot be undone.", confirmDelete, null);
    }

    const confirmDelete = async() =>{
        const tid = toast.loading('Canceling Order',{position:'bottom-right'});
        setIsCancelling(true);
        const resp = await fetchData('order/cancel/'+orderId);
        if(resp){
            toast.update(tid, { render: "Order Cancelled", type: "success", isLoading: false, autoClose: 2000 });
            setOrderDetails(null);
            setOrderItems([]);
            getData(orderId);
        }else{
            toast.dismiss();
        }
        setIsCancelling(false);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%', gap: 2}}>
            {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}} mb={2}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading Order Details</Typography>
            </Stack>}

            {orderDetails && unpaidSubs > 0 && <Alert variant="filled" severity="error">
                Customer has {unpaidSubs} UNPAID Subscriptions.
            </Alert>}

            {orderDetails && <Stack sx={{flexDirection: 'row', background: '#fff',  padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 2}}>
                    <Stack flex={1} gap={0.4}>
                        <Typography variant='h6'><b>Order No:</b> {orderDetails.orderId}</Typography>
                        {orderDetails.center && <Typography variant='h6'><b>Processing Center:</b> {orderDetails.center.name}</Typography>}
                        <Typography variant='h6'><b>Customer Name:</b> <Link to={`/customers/${orderDetails.user.id}`}><span style={{color: '#000'}}>{orderDetails.user.name}</span></Link></Typography>
                        <Typography variant='h6'><b>Customer Phone:</b> {orderDetails.user.phone}</Typography>
                        <Typography variant='h6'><b>Created:</b> {moment(orderDetails.created).format('Do MMMM YYYY h:mm a')}</Typography>
                        {orderDetails.centerInTime && <Typography variant='h6'><b>Processing Center In:</b> {moment(orderDetails.centerInTime).format('Do MMMM YYYY h:mm a')}</Typography>}
                        {orderDetails.centerOutTime && <Typography variant='h6'><b>Processing Center Out:</b> {moment(orderDetails.centerOutTime).format('Do MMMM YYYY h:mm a')}</Typography>}
                        <Typography variant='h6'><b>Total Items:</b> {orderDetails.totalItems}</Typography>
                        <Typography variant='h6'><b>Status:</b> {orderDetails.status}</Typography>
                        {orderDetails.deliveryDate && <Typography variant='h6'><b>Delivery Date:</b> {moment(orderDetails.deliveryDate).format('Do MMM YYYY h:mm a')}</Typography>}
                    </Stack>
                    <Stack sx={{flex: 1}} gap={1}>
                        <Stack flexDirection={'row'} justifyContent={'right'} gap={1}>
                            {orderDetails.statusCode !== -1 && <Box><Button variant='contained' color='error' onClick={cancelOrder}>Cancel Order</Button></Box>}
                            {orderDetails.invoiceDate && <Box><Link to={`${storeApiUrl}order/invoice/${orderId}`} target='_blank'><Button variant='contained'>Print Invoice</Button></Link></Box>}
                            {(orderDetails.deliveryBags.length > 0 || orderDetails.deliveryStoreBags.length > 0) && <Link to={`${storeApiUrl}order/deliverytags/${orderId}`} target='_blank'>
                                <Button variant='contained'>Print Delivery Tags</Button>
                            </Link>} 
                        </Stack>
                        <Stack flex={1} gap={0.4}>
                            {orderDetails.pickupTime && <Typography variant='h6'><b>Pickup Time:</b> {moment(orderDetails.pickupTime).format('Do MMMM YYYY h:mm a')}</Typography>}
                            <Typography variant='h6'><b>Pickup & Delivery Address</b></Typography>
                            <Stack>
                                <Typography>{orderDetails.address.name}</Typography>
                                <Typography>{orderDetails.address.phone}</Typography>
                                {orderDetails.address.address.split('\n').map((line, index)=>{
                                    return <Typography key={`${index}`} variant='body'>{line}</Typography>
                                })}
                                <Typography>{orderDetails.address.city}, {orderDetails.address.state.name} - {orderDetails.address.pincode}</Typography>
                            </Stack>
                            <Typography variant='h6'><b>Final Amount:</b> {currency}{orderDetails.total}</Typography>
                            <Typography variant='h6'><b>Payment Type:</b> {orderDetails.payType} {orderDetails.payStore? ' ('+orderDetails.payStore.name+')':''}{orderDetails.payRider? ' ('+orderDetails.payRider.name+')':''}</Typography>
                            {orderDetails.payStatus && orderDetails.total > 0 && <Typography variant='h6'><b>Payment Date:</b> {moment(orderDetails.payDate).format('Do MMM YYYY h:mm a')}</Typography>}
                        </Stack>
                    </Stack>
                </Stack>}

            {orderDetails && (orderDetails.pickupRider || orderDetails.deliveryRider || orderDetails.partner) && <Stack flexDirection={'row'} sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 1}}>
                {orderDetails.pickupRider && <Stack flex={1}>
                    <Typography variant='h6'><b>Pickup Person:</b> {orderDetails.pickupRider.name}</Typography>
                </Stack>}
                {orderDetails.deliveryRider && <Stack flex={1}>
                    <Typography variant='h6'><b>Delivery Person:</b> {orderDetails.deliveryRider.name}</Typography>
                </Stack>}
                {orderDetails.partner && <Stack flex={1}>
                    <Typography variant='h6'><b>Partner:</b> {orderDetails.partner.name}</Typography>
                </Stack>}
            </Stack>}

            {orderDetails && (orderDetails.pickupStore || orderDetails.deliveryStore) && <Stack flexDirection={'row'} sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 1}}>
                {orderDetails.pickupStore && <Stack flex={1}>
                    <Typography variant='h6'><b>Pickup Store:</b> {orderDetails.pickupStore.name}</Typography>
                </Stack>}
                {orderDetails.deliveryStore && <Stack flex={1}>
                    <Typography variant='h6'><b>Delivery Store:</b> {orderDetails.deliveryStore.name}</Typography>
                </Stack>}
            </Stack>}

            {orderDetails && orderDetails.totalItems > 0 && <Stack sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 1}}>
                <Typography variant='h5' mb={2}><b>Order Items</b></Typography>
                {orderDetails.itemTypes.map((service, index)=>{
                    return <Stack key={index}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'}>
                            <Stack flexDirection={'row'} gap={1}>
                                <Typography variant='h6'><b>{service.service.name}</b></Typography>
                                {service.service.type === "kg" && <Typography variant='h6'><b>({service.value} {service.service.unit})</b></Typography>}
                                <Typography variant='h6'>({service.count} items)</Typography>
                                {(orderDetails.status === "Receiving" || orderDetails.status === "Picked Up") && <Typography variant='h6'><b>(Bags: {service.bags})</b></Typography>}
                                {tagData[service.service.id] === true && <Link to={`${storeApiUrl}order/tags/${orderId}/${service.service.id}`} target='_blank'><Button variant='contained' size='small'>Print Tags</Button></Link>}
                            </Stack>
                            <Typography variant='h6'><b>{currency} {service.price}</b></Typography>
                        </Stack>
                        <Stack>
                            {bags.map(bag=>{
                                if(bag.service === service.service.id)
                                return <Stack flexDirection={'row'} key={bag.id} gap={10} mb={0.5}>
                                    <Box width={'50%'}>
                                        <Typography variant='body2' fontWeight={'bold'}>BAG: {bag.qrcode}</Typography>
                                    </Box>
                                    <Box width={'50%'}>
                                        <Typography variant='body2' fontStyle={'italic'}>{bag.status}</Typography>
                                    </Box>
                                </Stack>
                                else return null
                            })}
                        </Stack>
                        <Stack>
                        {orderItems.map((item)=>{
                            if(item.service === service.service.id){
                                return <Stack key={item.id} flexDirection={'row'} sx={{borderBottom: '1px solid #c4c5c5', padding: 1, borderRadius: 10, paddingX: 2}}>
                                    <Box width={'40%'}>
                                        <Stack gap={1} flexDirection={'row'}>
                                            <Typography>{item.name}</Typography>
                                            {service.service.type === "size" && <Typography> ({item.qty} {service.service.unit})</Typography>}
                                        </Stack>
                                    </Box>
                                    <Box width={'20%'}>
                                        {item.color === "white" && <Stack flexDirection={'row'} gap={1}>
                                            <WhiteColor />
                                            <Typography fontWeight={'550'}>White</Typography>
                                        </Stack>}
                                        {item.color === "black" && <Stack flexDirection={'row'} gap={1}>
                                            <BlackColor />
                                            <Typography fontWeight={'550'}>Black</Typography>
                                        </Stack>}
                                        {item.color === "color" && <Stack flexDirection={'row'} gap={1}>
                                            <ColoredColor />
                                            <Typography fontWeight={'550'}>Colored</Typography>
                                        </Stack>}
                                    </Box>
                                    <Box width={'30%'}>
                                        <Typography fontStyle={'italic'}>{item.status}</Typography>
                                    </Box>
                                    <Box width={'10%'} textAlign={'right'}>
                                        {service.service.type !== "kg" && <Typography>{currency} {item.price}</Typography>}
                                    </Box>
                                </Stack>
                            }else{
                                return null;
                            }
                        })}
                        </Stack>
                    </Stack>
                })}
            </Stack>}

            {orderDetails && (orderDetails.deliveryStoreBags.length > 0 || orderDetails.deliveryBags.length > 0) && <Stack sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 1}}>
                {orderDetails.deliveryStoreBags.length > 0 && <Stack flex={1}>
                    <Typography variant='h6'><b>Delivery Bags at Processing Center</b></Typography>
                    <Typography>{orderDetails.deliveryStoreBags.join(', ')}</Typography>
                </Stack>}
                {orderDetails.deliveryBags.length > 0 && <Stack flex={1}>
                    <Typography variant='h6'><b>Delivery Bags at Store / Rider</b></Typography>
                    <Typography>{orderDetails.deliveryBags.join(', ')}</Typography>
                </Stack>}
            </Stack>}

            {orderDetails && orderDetails.discounts.length > 0 && <Stack sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 1}}>
                <Typography variant='h6'><b>Discounts</b></Typography>
                {orderDetails.discounts.map((disc, index)=>{
                    return <Stack gap={1} key={index}>
                        <Stack flexDirection={'row'} gap={1}>
                            <Typography>{disc.name}:</Typography>
                            <Typography>{currency}{disc.amount}</Typography>
                        </Stack>
                    </Stack>
                })}
            </Stack>}

            {orderDetails && orderDetails.totalItems > 0 &&  <Stack sx={{background: '#fff', padding: 2, borderRadius: 3, boxShadow: '5px 3px 5px #00000005', gap: 1}}>
                <Stack flex={1} gap={0.4}>
                    <Typography variant='h6'><b>Total Amount:</b> {currency}{orderDetails.amount}</Typography>
                    <Typography variant='h6'><b>Subscription Discount:</b> {currency}{orderDetails.subscriptionDiscount}</Typography>
                    <Typography variant='h6'><b>Wallet Discount:</b> {currency}{orderDetails.walletDiscount}</Typography>
                    <Typography variant='h6'><b>Store Discount ({orderDetails.storeDiscountPercent}%):</b> {currency}{orderDetails.storeDiscount}</Typography>
                    <Typography variant='h6'><b>Final Amount:</b> {currency}{orderDetails.total}</Typography>
                    {orderDetails.refund > 0 && <Typography variant='h6'><b>Refund Amount:</b> {currency}{orderDetails.refund}</Typography>}
                </Stack>
            </Stack>}

        </Stack>
    )
}

export default OrderDetails