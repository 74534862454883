import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { currency, storeApiUrl, useApiRequest } from '../store/Common';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { Close, ExpandMore, SearchTwoTone } from '@mui/icons-material';

function SubscriptionsPage() {

    const {postData, fetchData} = useApiRequest();
    const [searchTerm, setSearchTerm] = useState('');
    const [bodyData, setBodyData] = useState({search: '', plans: [], sort: 'none'});
    const [subsList, setSubsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [plans, setPlans] = useState([]);
    const [sort, setSort] = useState('none');

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('subscription/lists/'+page, body);
        if(resp){
            setSubsList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setSearchTerm('');
        setSort('none');
        setSelectedPlans([]);
        setBodyData({search: '', plans: [], sort: 'none'});
        setSubsList([]);
        getData(1, {search: '', plans: [], sort: 'none'});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        setBodyData({search: searchTerm, plans: selectedPlans, sort: sort});
        setSubsList([]);
        getData(1, {search: searchTerm, plans: selectedPlans, sort: sort});
    }

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setSubsList([]);
            const resp = await postData('subscription/lists/1', {search: '', plans: [], sort: 'none'});
            const resp1 = await fetchData('plan/list');
            if(resp1){
                setPlans(resp1.data);
            }
            if(resp){
                setSubsList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[postData, fetchData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && subsList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, subsList.length, page, bodyData])
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const handlePlan = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedPlans(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Subscriptions ({totalDocs})</Typography>
                <Link to={'/purchases'}>
                    <Button variant='contained'>View Purchases</Button>
                </Link>
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <TextField fullWidth variant='outlined' label='Customer Name/Phone' value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                    <FormControl fullWidth>
                        <InputLabel>Plan</InputLabel>
                        <Select multiple value={selectedPlans} onChange={handlePlan} input={<OutlinedInput label="Chip" />} >
                            {plans.map(plan=>{
                                return <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Sort</InputLabel>
                        <Select value={sort} onChange={(e)=>{setSort(e.target.value)}} >
                            <MenuItem value='none'>None</MenuItem>
                            <MenuItem value='expasc'>Expiry Ascending</MenuItem>
                            <MenuItem value='expdsc'>Expiry Descending</MenuItem>
                            <MenuItem value='remasc'>Remaining Ascending</MenuItem>
                            <MenuItem value='remdsc'>Remaining Descending</MenuItem>
                            <MenuItem value='exprem'>Expired</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='15%'>
                    <Typography><b>Created</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='20%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='15%'>
                    <Typography><b>Plan Name</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>Remaining</b></Typography>
                </Box>
                <Box width='22%'>
                    <Typography><b>Expiry</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1} ref={containerRef}>
                {subsList.map((subs, index)=>{
                    let remaining = `${currency} ${subs.remaining}`;
                    if(subs.plan.type === "kg"){
                        remaining = `${subs.remaining} Kg`;
                    }
                    let rColor = '';
                    if(subs.remaining === 0){
                        rColor = 'error';
                    }
                    let eColor = '';
                    if(subs.expiry < Date.now()){
                        eColor = 'error';
                    }
                    const hasUnpaid = subs.purchases.some(purchase => purchase.payStatus === 'U' && !purchase.cancelled);
                    return <Stack key={index} width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Stack flexDirection='row' p={1.2} alignItems='center' width='100%'>
                            <Box width='15%'>
                                <Typography>{moment(subs.created).format('Do MMMM YYYY h:mm a')}</Typography>
                            </Box>
                            <Box width='20%'>
                                <Link to={`/customers/${subs.user.id}`}>
                                    <Typography color='rgb(71, 85, 105)'>{subs.user.name}</Typography>
                                </Link>
                            </Box>
                            <Box width='20%'>
                                <Typography>{subs.user.phone}</Typography>
                            </Box>
                            <Stack width='15%' flexDirection={'row'} gap={1}>
                                <Typography>{subs.plan.name}</Typography>
                                {hasUnpaid && <Typography fontWeight={'bold'} color='red'>UNPAID</Typography>}
                            </Stack>
                            <Box width='8%'>
                                <Typography color={rColor}>{remaining}</Typography>
                            </Box>
                            <Box width='22%'>
                                <Typography color={eColor}>{moment(subs.expiry).format('Do MMMM YYYY h:mm a')}</Typography>
                            </Box>
                        </Stack>
                        {subs.note && subs.note !== "" && <Box px={1} pb={1}>
                            <Typography><b>NOTE</b></Typography>
                            {subs.note.split('\n').map((line, index)=>{
                                return <Typography key={index}>{line}</Typography>
                            })}  
                        </Box>}
                        <Box>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography fontWeight={'bold'}>Purchases</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack flexDirection='row' alignItems='center' width='100%' mb={0.5} >
                                        <Box width='20%' paddingX={1}><Typography><b>Date</b></Typography></Box>
                                        <Box width='15%'><Typography><b>Payment Type</b></Typography></Box>
                                        <Box width='10%'><Typography><b>Value</b></Typography></Box>
                                        <Box width='10%'><Typography><b>Price</b></Typography></Box>
                                        <Box width='15%' paddingX={1}><Typography><b>Transaction ID</b></Typography></Box>
                                        <Box width='10%'><Typography><b>Payment</b></Typography></Box>
                                        <Box width='10%'><Typography><b>Invoice</b></Typography></Box>
                                    </Stack>
                                    {subs.purchases.map((pur)=>{
                                        let payStatus = 'Unpaid';
                                        let tColor = 'error';
                                        if(pur.payStatus === "S"){
                                            payStatus = pur.payBy;
                                            tColor = 'green'
                                        }else if(pur.payStatus === "P"){
                                            payStatus = "Processing";
                                            tColor = ''
                                        }
                                        let value = `${currency} ${pur.amount}`;
                                        if(subs.plan.type === "kg"){
                                            value = `${pur.amount} Kg`;
                                        }
                                        return <Stack key={pur.id} width={'100%'} flexDirection={'row'} marginBottom={1}>
                                            <Box width={'20%'} paddingX={1}><Typography>{moment(pur.created).format('Do MMMM YYYY h:mm a')}</Typography></Box>
                                            <Box width={'15%'}><Typography color={tColor}>{payStatus}</Typography></Box>
                                            <Box width='10%'><Typography>{value}</Typography></Box>
                                            <Stack width='10%' flexDirection={'row'} gap={1}>
                                                <Typography>{currency} {pur.price}</Typography>
                                                <Typography sx={{fontWeight: 'bold', color: 'red'}}>{pur.cancelled?"(CANCELLED)":""}</Typography>
                                            </Stack>
                                            <Box width='15%' paddingX={1}><Typography>{pur.payId}</Typography></Box>
                                            <Box width='10%'>
                                                {pur.payStatus !== "U" && pur.payDate && <Typography>{moment(pur.payDate).format('Do MMMM YYYY h:mm a')}</Typography>}
                                            </Box>
                                            <Box width='10%'>
                                                <Link to={`${storeApiUrl}subscription/invoice/${pur.id}`} target='_blank'><Button variant='contained' size='small'>Invoice</Button></Link>
                                            </Box>
                                        </Stack>
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Subscriptions</Typography>
                </Stack>}
                {subsList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Subscriptions to show</Typography>
                </Stack>}
            </Stack>
        </Stack>
    )
}

export default SubscriptionsPage