import React, { useCallback, useEffect, useRef, useState } from 'react'
import { apiUrl, currency, storeApiUrl, useApiRequest } from '../store/Common';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { Close, SearchTwoTone } from '@mui/icons-material';
import { DateRangePicker } from 'rsuite';
import { Link } from 'react-router-dom';

function SubPurchasePage() {

    const {fetchData, postData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const [purchaseList, setPurchaseList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef();
    const [page, setPage] = useState(2);
    const [totalDocs, setTotalDocs] = useState(0);
    const [bodyData, setBodyData] = useState({plans: [], start: 0, end: 0, payment: []});
    const [selectedDate, setSelectedDate] = useState(null);

    const [selectedPlans, setSelectedPlans] = useState([]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
    const [plans, setPlans] = useState([]);

    const getData = useCallback(async(page, body) =>{
        setIsLoading(true);
        const resp = await postData('subscription/purchases/'+page, body);
        if(resp){
            setPurchaseList((prevArray) => [...prevArray, ...resp.data.docs]);
            setPage(resp.data.nextPage);
            setTotalDocs(resp.data.totalDocs);
        }
        setIsLoading(false);
    },[postData])

    useEffect(()=>{
        const getOneData = async() =>{
            setIsLoading(true);
            setPurchaseList([]);
            const resp = await postData('subscription/purchases/1', {plans: [], start: 0, end: 0, payment: []});
            const resp1 = await fetchData('plan/list');
            if(resp1){
                setPlans(resp1.data);
            }
            if(resp){
                setPurchaseList(resp.data.docs);
                setPage(resp.data.nextPage);
                setTotalDocs(resp.data.totalDocs);
            }
            setIsLoading(false);
        }
        getOneData();
    },[fetchData, postData]);

    const debounce = (func, delay) => {
        let timer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const handleScroll = useCallback(() => {
        const currentContainer = containerRef.current;
        if (currentContainer && purchaseList.length > 0 && !isLoading) {
            const scrollOffset = currentContainer.scrollHeight - (currentContainer.scrollTop + currentContainer.clientHeight);
            if(currentContainer.scrollTop > 0 && scrollOffset >= 0 && scrollOffset < 10 && page){
                getData(page, bodyData);
            }
        }
    },[getData, isLoading, purchaseList.length, page, bodyData])

    const handlePlan = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedPlans(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handlePaymentChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedPaymentStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const resetList = () =>{
        if(isLoading){
            return;
        }
        setBodyData({plans: [], start: 0, end: 0, payment: []});
        setSelectedPlans([]);
        setSelectedPaymentStatus([]);
        setSelectedDate(null);
        setPurchaseList([]);
        getData(1, {plans: [], start: 0, end: 0, payment: []});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            plans: selectedPlans,
            start,
            end,
            payment: selectedPaymentStatus
        }
        setBodyData(body);
        setPurchaseList([]);
        getData(1, body);
    }
    
    //on scroll trigger
    useEffect(() => {
        const currentContainer = containerRef.current;
        const debouncedScrollHandler = debounce(handleScroll, 500);
        currentContainer.addEventListener('scroll', debouncedScrollHandler);
        return () => {
            currentContainer.removeEventListener('scroll', debouncedScrollHandler);
        };
    }, [handleScroll]);

    const exportCsv = () =>{
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            plans: selectedPlans,
            start,
            end
        }
        const url = `${apiUrl}subscription/csv?session=${localStorage.getItem('session')}&data=${encodeURIComponent(JSON.stringify(body))}`;
        window.open(url, '_blank');
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Stack px={2} flexDirection='row' justifyContent='space-between'>
                <Typography variant='h4'>Subscription Purchases ({totalDocs})</Typography>
                <Button variant='contained' onClick={exportCsv}>Export</Button>
            </Stack>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <Box width={500}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Box>
                    <FormControl fullWidth>
                        <InputLabel>Plan</InputLabel>
                        <Select multiple value={selectedPlans} onChange={handlePlan} input={<OutlinedInput label="Chip" />} >
                            {plans.map(plan=>{
                                return <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Payment Status</InputLabel>
                        <Select multiple value={selectedPaymentStatus} onChange={handlePaymentChange} >
                            <MenuItem value='Cash'>Cash</MenuItem>
                            <MenuItem value='Cash at Rider'>Cash at Rider</MenuItem>
                            <MenuItem value='Card'>Card</MenuItem>
                            <MenuItem value='Online'>Online</MenuItem>
                            <MenuItem value='UPI'>UPI</MenuItem>
                            <MenuItem value='Unpaid'>Unpaid</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            <Stack mt={2.5} flexDirection='row'alignItems='center' width='100%' paddingX={4}>
                <Box width='15%'>
                    <Typography><b>Date</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Plan</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Customer Name</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Customer Phone</b></Typography>
                </Box>
                <Box width='7%'>
                    <Typography><b>Value</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Price</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Payment Type</b></Typography>
                </Box>
                <Box width='8%'>
                    <Typography><b>Payment Date</b></Typography>
                </Box>
                <Box width='10%' paddingX={1}>
                    <Typography><b>Transaction ID</b></Typography>
                </Box>
                <Box width='10%'>
                    <Typography><b>Invoice</b></Typography>
                </Box>
            </Stack>
            <Stack mt={1} p={2} pt={0} height='calc(100vh - 338px)' overflow='auto' gap={1} ref={containerRef}>
                {purchaseList.map((purchase, index)=>{
                    let payStatus = 'Unpaid';
                    let tColor = 'error';
                    if(purchase.payStatus === "S"){
                        payStatus = purchase.payBy;
                        tColor = 'green'
                    }else if(purchase.payStatus === "P"){
                        payStatus = "Processing";
                        tColor = ''
                    }
                    let value = `${currency} ${purchase.amount}`;
                    if(purchase.plan.type === "kg"){
                        value = `${purchase.amount} Kg`;
                    }
                    return <Stack key={index} flexDirection='row' p={1.2} alignItems='center' width='100%' sx={{background:'#fff', border: '1px solid rgb(226, 232, 240)', borderRadius: '9px', boxShadow: '5px 3px 5px #00000005', color: 'rgb(71, 85, 105)'}}>
                        <Box width='15%'>
                            <Typography>{moment(purchase.created).format('Do MMMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Typography>{purchase.plan.name}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Link to={`/customers/${purchase.user.id}`}>
                                <Typography color='rgb(71, 85, 105)'>{purchase.user.name}</Typography>
                            </Link>
                        </Box>
                        <Box width='10%'>
                            <Typography>{purchase.user.phone}</Typography>
                        </Box>
                        <Box width='7%'>
                            <Typography>{value}</Typography>
                        </Box>
                        <Stack width='10%' flexDirection={'row'} gap={1}>
                            <Typography>{currency} {purchase.price}</Typography>
                            <Typography sx={{fontWeight: 'bold', color: 'red'}}>{purchase.cancelled?"(CANCELLED)":""}</Typography>
                        </Stack>
                        <Box width='10%'>
                            <Typography color={tColor}>{payStatus}</Typography>
                        </Box>
                        <Box width='8%'>
                            <Typography>{moment(purchase.payDate).format('Do MMMM YYYY h:mm a')}</Typography>
                        </Box>
                        <Box width='10%' paddingX={1}>
                            <Typography>{purchase.payId}</Typography>
                        </Box>
                        <Box width='10%'>
                            <Link to={`${storeApiUrl}subscription/invoice/${purchase.id}`} target='_blank'>
                                <Button variant='contained' size='small'>Invoice</Button>
                            </Link>
                        </Box>
                    </Stack>
                })}
                {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader size={15} color='#000' />
                    <Typography variant='body'>Loading Subscription Purchases</Typography>
                </Stack>}
                {purchaseList.length === 0 && !isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body'>No Subscription Purchases to show</Typography>
                </Stack>}
            </Stack>

        </Stack>
    )
}

export default SubPurchasePage